const color = (r, g, b) => `rgb(${ r },${ g },${ b })`
const colors = [
  color(70, 150, 180),
  color(170, 200, 70),
  color(200, 50, 40),
  color(50, 30, 180),
  // color(230,200,90),
  color(120, 160, 10),
  // color(225,240,70),
  color(230, 170, 70),
  color(160, 80, 220),
  color(195, 30, 125),
  color(30, 90, 180),
  color(255, 110, 100),
  color(110, 30, 170),
]

export default colors