import React from 'react'
import { isExcluded, isSingle } from '../helpers'
import colors from '../colors'
import numbers from '../numbers'

const Subcalc = props => {
  const stringVal = props.value.toString()
  const total = stringVal.split('').reduce((char, current) => char + parseInt(current), 0)

  // Set result in case props.value is already excluded
  if (isExcluded(props.value) || isSingle(props.value)) {
    if (props.set && props.value !== props.result) {
      props.set(props.value)
    }
    return null
  }

  // Set result to total if total is the final result
  if (props.set && total !== props.result && (isSingle(total) || isExcluded(total))) {
    props.set(total)
  }

  const color = colors[numbers.indexOf(total)]

  return (
    <>
      {
        !isSingle(props.value) && !isExcluded(props.value) && (
          <span> = ({
            stringVal.split('').join(' + ')
          }) = { React.createElement(props.wrapper, { children: total, lifeNumber: total, color }) }</span>
        )
      }
      {
        !isSingle(total) && !isExcluded(total) && (
          <Subcalc
            value={ total }
            set={ props.set }
            result={ props.result }
            wrapper={ props.wrapper }
          />
        )
      }
    </>
  )
}

export default Subcalc