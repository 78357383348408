import React, { useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/nl';

import Subcalc from './subcalc'
import { ReactComponent as InformationSVG } from '../assets/information.svg'
import colors from '../colors'
import lifenumbers from '../numbers'
import { isExcluded, isSingle } from '../helpers'
import config from '../config'

moment.locale(config.momentLocale)

const FormContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  z-index: 2;
`
const CalcContainer = styled.div`
  font-family: 'Courgette';
  opacity: ${ props => props.isHidden ? 0 : 1 };
  transition: opacity 0.4s ease-out, max-height 0.4s ease;
  overflow: hidden;
  max-height: ${ props => props.isHidden ? 0 : '300px' };
  margin-top: 10px;
`

const TotalWrapper = styled.span``

const CalcDay = styled.div`
  ${ TotalWrapper } {
    color: ${ colors[0] };
  }
`
const CalcMonth = styled.div`
  ${ TotalWrapper } {
    color: ${ colors[1] };
  }
`
const CalcYear = styled.div`
  ${ TotalWrapper } {
    color: ${ colors[2] };
  }
`

const CalcLabel = styled.span`
  display: inline-block;
  width: 90px;
`

const CalcLifenumber = styled.div`
  margin-top: 10px;
`

const Green = styled.span`
  color: ${ colors[0] };
`
const Red = styled.span`
  color: ${ colors[1] };
`
const Blue = styled.span`
  color: ${ colors[2] };
`

const DaySelect = styled(Select)`
  width: 85px;
  margin-right: 5px;
  font-family: 'Courgette';
`

const MonthSelect = styled(Select)`
  width: 120px;
  margin-right: 5px;
  font-family: 'Courgette';
`

const YearSelect = styled(Select)`
  width: 100px;
  font-family: 'Courgette';
`

const StyledInformationSVG = styled(InformationSVG)`
  margin-left: 5px;
  opacity: ${ props => props.active ? 1 : 0.5 };
  transition: opacity 0.5s ease-out;
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Courgette';
  cursor: pointer;
`

const ShowCalculation = styled.span`
  opacity: ${ props => props.active ? 1 : 0.5 };
  transition: opacity 0.5s ease-out;
  margin-left: 10px;
`

const LifeNumber = styled.span`
  ${ ({ lifeNumber, color }) => (isExcluded(lifeNumber) || isSingle(lifeNumber)) && `
    background: ${ color };
    display: inline-block;
    padding: 2px 5px;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.3);
    color: #FFF;
  `}
`

const Form = props => {
  const currentYear = moment().year()

  const [daySelected, setDaySelected] = useState(null),
    [monthSelected, setMonthSelected] = useState(null),
    [yearSelected, setYearSelected] = useState(null),
    [dayLifenumber, setDayLifenumber] = useState(0),
    [monthLifenumber, setMonthLifenumber] = useState(0),
    [yearLifenumber, setYearLifenumber] = useState(0),
    [calculationHidden, setCalculationHidden] = useState(true)

  const day = (daySelected && daySelected.value) || 0
  const month = (monthSelected && monthSelected.value) || 0
  const year = (yearSelected && yearSelected.value) || currentYear

  const onYearChange = selected => {
    if (!props.hasSelected) {
      props.setHasSelected(true)
    }
    setYearSelected(selected)
  }

  const prettyDay = day + 1
  const prettyMonth = month + 1

  const lifeNumber = dayLifenumber + monthLifenumber + yearLifenumber
  const lifeNumberColor = colors[lifenumbers.indexOf(lifeNumber)]

  const DateComponents = {
    day: <DaySelect
      value={ daySelected }
      onChange={ selected => setDaySelected(selected) }
      options={ [...Array(31)].map((_, index) => ({ value: index, label: index + 1 })) }
      placeholder={ config.translations.day }
      key="day"
    />,
    month: <MonthSelect
      value={ monthSelected }
      onChange={ selected => setMonthSelected(selected) }
      options={ moment.months().map((month, index) => ({ value: index, label: month })) }
      placeholder={ config.translations.month }
      key="month"
    />,
    year: <YearSelect
      value={ yearSelected }
      onChange={ onYearChange }
      options={ [...Array(120)].map((_, index) => ({ value: currentYear - index, label: currentYear - index })) }
      placeholder={ config.translations.year }
      key="year"
    />
  }

  return (
    <>
      <FormContainer>
        { config.dateOrder.map(component => DateComponents[component]) }
      </FormContainer>
      <ToggleWrapper
        onClick={ () => setCalculationHidden(!calculationHidden) }
      >
        <StyledInformationSVG
          width={ 23 }
          active={ !calculationHidden ? 1 : 0 }
        />
        <ShowCalculation
          active={ !calculationHidden }
        >
          { config.translations.calculation }
        </ShowCalculation>
      </ToggleWrapper>
      <CalcContainer isHidden={ calculationHidden }>
        <CalcDay>
          <CalcLabel>{ config.translations.day }</CalcLabel>= <Green>{ prettyDay }</Green>
          <Subcalc
            value={ prettyDay }
            set={ setDayLifenumber }
            result={ dayLifenumber }
            wrapper={ TotalWrapper }
          />
        </CalcDay>
        <CalcMonth>
          <CalcLabel>{ config.translations.month }</CalcLabel>= <Red>{ prettyMonth }</Red>
          <Subcalc
            value={ prettyMonth }
            set={ setMonthLifenumber }
            result={ monthLifenumber }
            wrapper={ TotalWrapper }
          />
        </CalcMonth>
        <CalcYear>
          <CalcLabel>{ config.translations.year }</CalcLabel>= <Blue>{ year }</Blue>
          <Subcalc
            value={ year }
            set={ setYearLifenumber }
            result={ yearLifenumber }
            wrapper={ TotalWrapper }
          />
        </CalcYear>
        <CalcLifenumber>
          <CalcLabel>{ config.translations.lifenumber }</CalcLabel>=
          (<Green>{ dayLifenumber }</Green> + <Red>{ monthLifenumber }</Red> + <Blue>{ yearLifenumber }</Blue>)
          = <LifeNumber color={ lifeNumberColor } lifeNumber={ lifeNumber }>{ lifeNumber }</LifeNumber>
          <Subcalc
            value={ lifeNumber }
            set={ props.setLifenumber }
            result={ props.lifenumber }
            wrapper={ LifeNumber }
          />
        </CalcLifenumber>
      </CalcContainer>
    </>
  )
}

export default Form