import ReactDOM from 'react-dom'
import React, { useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import Form from './components/form'
import Lifenumbers from './components/lifenumbers'
import colors from './colors'
import lifenumbers from './numbers'

import './styles.css'
import config from './config'

const bg = keyframes`
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
`

const CTA = styled.div`
  box-shadow: 0 10px 14px -7px rgba(0,0,0,0.3);
  font-family: 'Courgette';
  font-size: 19px;
  cursor: pointer;
  margin-top: 30px;
  padding: 15px 20px;
  position: relative;
  
  background: ${ props => props.color };
  border: 0;
  border-radius: 50px;
  color: white;
  outline: none;
  
  transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
  
  &:focus { outline: none;}
  &:active { transform: scale(.9); }
  
  &:hover {
    &:before {
      animation: ${ bg } 0.5s 0.1s ease;
    }
  }
  
  &:before {
    position: absolute;
    transition: all .3s ease;
    content: '';
    top: 0; left: 0;
    height: 100%; width: 100%;
    background: ${ props => props.color };
    border-radius: 50px;
    z-index: -1;
    animation: ${ props => props.hasSelected ? css`${ bg } 0.5s 0.1s ease` : 'none' };
  }  
`

const Lifepath = () => {
  const [lifenumber, setLifenumber] = useState(0),
    [hasSelected, setHasSelected] = useState(false)
  const randomCTA = config.CTAs[Math.floor(Math.random()*config.CTAs.length)]
  return (
    <>
      <Form
        lifenumber={ lifenumber }
        setLifenumber={ setLifenumber }
        hasSelected={ hasSelected }
        setHasSelected={ setHasSelected }
      />
      <Lifenumbers
        hasSelected={ hasSelected }
        lifenumber={ lifenumber }
      />
      <CTA
        hasSelected={ hasSelected }
        color={ colors[lifenumbers.indexOf(lifenumber)] }
        onClick={() => {
          window.gtag('event', 'conversion', {
            'event_category': config.category,
            'event_label': `lifenumber ${randomCTA}`,
            'value': 1,
            'send_to': 'UA-135929033-1'
          })
          window.open(config.affiliate, '_blank')
        }}
      >
        { randomCTA }
      </CTA>
    </>
  )
}

const renderIfExists = () => {
  const target = document.getElementById('lifepath-widget');
  if (typeof(target) != 'undefined' && target !== null) {
    ReactDOM.render(<Lifepath/>, document.getElementById('lifepath-widget'))
    return true;
  }
  return false;
};

setTimeout(() => {
  if (!renderIfExists()) {
    const elementPollInterval = setInterval(() => {
      if (renderIfExists()) {
        clearInterval(elementPollInterval);
      }
    }, 1000)
  }
}, 1000);

// Inject analytics
const analytics = document.createElement('script')
analytics.async = true
analytics.src = "https://www.googletagmanager.com/gtag/js?id=UA-135929033-1"
document.body.appendChild(analytics)

window.dataLayer = window.dataLayer || [];
window.gtag = function(){window.dataLayer.push(arguments);}
window.gtag('js', new Date());
window.gtag('config', 'UA-135929033-1', {
  'page_title' : 'clairvoyant-lifenumber-widget',
});
window.gtag('set', {'content_group1': 'lifenumber-widget'});