export default {
  category: "psychicworld", // Analytics event category
  affiliate: "https://www.psychicworld.com/psychics?aff=PJW1J",
  dateOrder: ["month", "day", "year"],
  momentLocale: 'en',
  translations: {
    calculation: "Show calculation",
    day: "Day",
    month: "Month",
    year: "Year",
    lifenumber: "Lifenumber"
  },
  CTAs: [
    "Meaning of my number?",
  ]
}
