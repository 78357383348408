import React from 'react'
import styled from 'styled-components'

import colors from '../colors'
import existingNumbers from '../numbers'
import config from '../config'

const Shadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(circle at 52% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05) 45%, rgba(0, 0, 0, 0) 50%);
  transition: transform 0.8s ease-out;
  z-index: -1;
`

const Cloud = styled.img`
  position: absolute;
  left: ${ props => props.left }px;
  top: ${ props => props.top }%;
  transform: translateX(0px) rotate(${ props => props.rotate }deg);
  animation: move-clouds-${ props => props.rotate } ${ props => props.duration }ms infinite linear;
  animation-delay: ${ props => props.delay }ms;
  opacity: 0;
  transition: opacity 0.8s ease-out;
  
  @keyframes move-clouds-${ props => props.rotate } {
    0% {
      transform: translateX(0%) rotate(${ props => props.rotate }deg);
    }
    100% {
      transform: translateX(-295px) rotate(${ props => props.rotate - 90 }deg);
    }
  }
`

const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${ props => colors[props.index] };
  transition: opacity 0.8s ease-out;
`

const Number = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  position: relative;
  overflow: hidden;
  
  transform: translateY(0px);
  transition: transform 0.8s ease-out;
  
  ${ Cloud } {
    opacity: ${ props => props.isActive ? 1 : 0.5 };
  }
  
  ${ BackgroundColor } {
    opacity: ${ props => props.isActive ? 1 : 0.25 };
  }
`


const Stage = styled.div`
  perspective: 240px;
  perspective-origin: 50% 50%;
  position: relative;
  margin: 10px;
  
  cursor: pointer;
    
  &:hover ${ Shadow } {
    transform: rotateX(90deg) translateZ(-30px) scale(1.3);
  }
  &:hover ${ Number } {
    transform: translateY(-10px);
  }
  &:hover ${ BackgroundColor } {
    opacity: 1;
  }
  &:hover ${ Cloud } {
    opacity: 1;
  }

  ${ Number } {
    transform: translateY(${ props => props.selected ? '-10px' : '0px' });
  }
  ${ Shadow } {
    transform: rotateX(90deg) translateZ(-30px) scale(${ props => props.selected ? '1.3' : '1' });
  }
`

const Shading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(circle at 33% 33%, rgba(255,255,255,0.5), rgba(0,0,0,0.3));
  
  &:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 0px, rgba(255,255,255,0.8), rgba(255, 255, 255, 0) 58%);
    filter: blur(1px);
    z-index: 2;
  }
`

const Text = styled.span`
  position: relative;
  font-family: 'Courgette';
  font-weight: 700;
  color: rgba(0,0,0,0.5);
  font-size: 17px;
  z-index: 10;
`

const NumberContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  align-items: center;
  justify-content: center;
`

const square = [0, 90, 180, 270]
const left = [0, 24, -10, -15]
const randomDuration = existingNumbers.map(() => Math.floor(Math.random() * 2000) + 36000)
const randomDelay = existingNumbers.map(() => Math.floor(Math.random() * 7000))
const randomNumberOfSquare = existingNumbers.map(() => Math.floor(Math.random() * 4))
const randomTop = existingNumbers.map(() => Math.floor(Math.random() * 10) + 15)
const randomRotate = existingNumbers.map(() => Math.floor(Math.random() * 180))

const Lifenumbers = props => {
  return (
    <NumberContainer>
      {
        existingNumbers.map((number, index) => {
          return (
            <Stage
              selected={ props.hasSelected && number === props.lifenumber }
              key={ number }
              onClick={ () => {
                window.gtag('event', 'conversion', {
                  'event_category': config.category,
                  'event_label': 'lifenumber-widget-lifenumber',
                  'value': 1,
                  'send_to': 'UA-135929033-1'
                })
                window.open(config.affiliate, '_blank')
              } }
            >
              <Number key={ number } isActive={ !props.hasSelected || number === props.lifenumber }>
                <BackgroundColor index={ index }/>
                <Cloud src={process.env.PUBLIC_URL + "/images/cloud-white.png"}
                       left={ left[randomNumberOfSquare[index]] }
                       rotate={ square[randomNumberOfSquare[index]] }
                       top={ randomTop[index] }
                       delay={ randomDelay[index] }
                       duration={ randomDuration[index] }
                />
                <Cloud
                  src={process.env.PUBLIC_URL + "/images/cloud-white.png"}
                  left={ 100 }
                  top={ 20 }
                  rotate={ randomRotate[index] }
                  delay={ randomDelay[index] }
                  duration={ randomDuration[index] }
                />
                <Text>{ number }</Text>
                <Shading/>
              </Number>
              <Shadow/>
            </Stage>
          )
        })
      }
    </NumberContainer>
  )
}

export default Lifenumbers